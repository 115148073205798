import React, { useEffect } from "react";
import { ThemeProvider, CssBaseline, Theme } from "@material-ui/core";

import { Main } from "./components/main";
import { loadAxios } from "../application/axios";
import { TouchDialog, TouchDialogProvider, CookieConsent } from "./shared/components";

// Cookie consent constants
const COOKIE_CONSENT_KEY = "cookieConsent";

declare global {
  interface Window {
    fbq: any;
  }
}

// Check if user has accepted cookies
const hasAcceptedCookies = () => {
  return localStorage.getItem(COOKIE_CONSENT_KEY) === "accepted";
};

loadAxios();

export const Application = ({ theme }: { theme: Theme }) => {
  useEffect(() => {
    let isInitialized = false;
    let lastScrollDepth = 0;
    let lastTrackedPath = window.location.pathname;

    // Function to check if Meta Pixel is loaded
    const checkMetaPixel = () => {
      if (typeof window.fbq === 'undefined') {
        console.warn('Meta Pixel is not available, retrying in 1 second...');
        setTimeout(checkMetaPixel, 1000);
        return false;
      }
      return true;
    };

    // Calculate scroll depth percentage
    const getScrollDepth = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return Math.round((scrollTop / (documentHeight - windowHeight)) * 100);
    };

    // Track scroll depth
    const handleScroll = () => {
      if (!window.fbq || !isInitialized) return;

      const currentDepth = getScrollDepth();
      const milestones = [25, 50, 75, 90, 100];

      // Find the highest milestone passed
      const passedMilestone = milestones.reverse().find(milestone =>
        currentDepth >= milestone && lastScrollDepth < milestone
      );

      if (passedMilestone) {
        window.fbq('trackCustom', 'ScrollDepth', {
          depth_percentage: passedMilestone,
          page_url: window.location.pathname,
          page_title: document.title
        });
      }

      lastScrollDepth = currentDepth;
    };

    // Track specific page views based on path
    const trackPageView = (path: string) => {
      if (!window.fbq || !isInitialized) return;

      // Standard PageView event
      window.fbq('track', 'PageView');

      // Map paths to their specific view names
      const pageViewMap: Record<string, string> = {
        '/': 'HomePageView',
        '/what-is-it': 'WhatIsItPageView',
        '/how-it-works': 'HowItWorksPageView',
        '/why-it-works': 'WhyItWorksPageView',
        '/needs-drivers': 'NeedsDriversPageView'
      };

      // Track custom page view if path exists in map
      const customViewName = pageViewMap[path];
      if (customViewName) {
        window.fbq('trackCustom', customViewName);
        console.log(`Tracked ${customViewName} for path: ${path}`);
      }
    };

    // Modified function to respect cookie consent
    const initializeTracking = () => {
      // Only initialize tracking if user has accepted cookies
      if (!hasAcceptedCookies()) {
        console.info('User declined non-essential cookies. Analytics tracking disabled.');
        return null; // Return null if consent not given
      }

      // Original tracking initialization logic
      if (isInitialized) return;
      isInitialized = true;

      // Check if Meta Pixel is available
      if (!checkMetaPixel()) return null;

      // Track initial page view
      trackPageView(lastTrackedPath);

      // Add scroll listener with throttling
      let scrollTimeout: NodeJS.Timeout | null = null;
      const throttledScroll = () => {
        if (scrollTimeout) return;
        scrollTimeout = setTimeout(() => {
          handleScroll();
          scrollTimeout = null;
        }, 500);
      };

      window.addEventListener('scroll', throttledScroll, { passive: true });

      // Track button clicks and link interactions
      const handleClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        // Find clickable element (button or link) - check parent elements too
        const clickable = target.closest('button, a, [role="button"], .MuiButton-root, .btn, .MuiButtonBase-root');
        if (!clickable) return;

        // Get text content and metadata
        const exactText = clickable.textContent?.trim() || '';
        const href = clickable.getAttribute('data-href') || clickable.getAttribute('href');
        const id = clickable.id;
        const classes = Array.from(clickable.classList).join(' ');

        // Common event parameters
        const eventParams = {
          element_type: clickable.tagName.toLowerCase(),
          element_text: exactText,
          element_class: classes,
          element_id: id || 'none',
          has_redirect: !!href,
          url: window.location.pathname
        };

        // Track specific buttons based on content
        if (exactText === 'Login') {
          window.fbq('track', 'LoginClick', {
            content_name: 'Login Button Click',
            ...eventParams
          });
        }
        else if (exactText === 'Join for Free') {
          window.fbq('track', 'JoinforFreeClick', {
            content_name: 'Join Free Button Click',
            ...eventParams
          });
        }
        else if (exactText.toLowerCase().includes('google play') || href?.includes('play.google.com')) {
          window.fbq('trackCustom', 'GooglePlayClick', {
            content_name: 'Google Play Button Click',
            ...eventParams
          });
        }
        else if (exactText.toLowerCase().includes('beneficiary')) {
          window.fbq('trackCustom', 'BeneficiaryClick', {
            content_name: 'Become Beneficiary Click',
            ...eventParams
          });
        }
        else if (exactText.toLowerCase().includes('sponsor')) {
          window.fbq('trackCustom', 'SponsorClick', {
            content_name: 'Become Sponsor Click',
            ...eventParams
          });
        }
        else {
          // Track other clicks without duplicating events
          window.fbq('trackCustom', 'ElementClick', eventParams);
        }
      };

      // Add click listener
      document.addEventListener('click', handleClick, true);

      return () => {
        document.removeEventListener('click', handleClick, true);
        window.removeEventListener('scroll', throttledScroll);
        if (scrollTimeout) clearTimeout(scrollTimeout);
        isInitialized = false;
      };
    };

    // Only initialize tracking if user has accepted cookies
    const cleanup = hasAcceptedCookies() ? initializeTracking() : null;

    // We don't need to listen for history changes anymore since we're not navigating between pages
    // Just track initial page load
    if (hasAcceptedCookies()) {
      trackPageView('/');
    }

    return () => {
      cleanup?.();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <TouchDialogProvider>
        <TouchDialog />
        <div id="rootbar"></div>
        <CssBaseline />
        <div style={{
          minHeight: "100vh",
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#000000"
        }}>
          <Main />
          <CookieConsent />
        </div>
      </TouchDialogProvider>
    </ThemeProvider>
  );
};
