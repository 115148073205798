import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useStyles } from "./styles";

export const WhatIsIt = (props: RouteComponentProps & { theme?: Theme }) => {
  const styles = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={styles.container}>
      <div className={styles.decorativeCircle1}></div>
      <div className={styles.decorativeCircle2}></div>
      <div className={styles.decorativeCircle3}></div>
      <div className={styles.decorativeLine2}></div>

      <div className={styles.contentWrapper}>
        <Typography variant="h2" className={styles.title}>
          <span className={styles.greenText}>InWin:</span> What is it?
        </Typography>

        <Typography variant="h5" className={styles.subtitle}>
          InWin is the platform for fans to affect change through the sports
          and athletes they love
        </Typography>

        <div className={styles.donationCardsWrapper}>
          <div className={styles.donationCard} style={{
            top: isMobile ? '25%' : '65%',
            left: isMobile ? '10%' : '15%',
            maxWidth: isMobile ? '180px' : '250px'
          }}>
            <span className={styles.greenText}>Donate for every win!</span>
          </div>

          <div className={styles.donationCard} style={{
            top: isMobile ? '45%' : '65%',
            right: isMobile ? '10%' : '15%',
            maxWidth: isMobile ? '180px' : '250px'
          }}>
            <span className={styles.greenText}>Donate for every shutout!</span>
          </div>

          <div className={styles.donationCard} style={{
            top: isMobile ? '70%' : '95%',
            left: '55%',
            transform: 'translateX(-50%)',
            maxWidth: isMobile ? '280px' : '400px'
          }}>
            <span className={styles.greenText}>Donate for every goal, triple-double, touchdown, or homerun!</span>
          </div>
        </div>
      </div>
    </Box>
  );
};
