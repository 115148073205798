import React from "react";
import { IBeneficiaryProps } from "./IBeneficiaryProps";
import { BecomeBeneficiaryCard } from "./becomeBeneficiary";
import { Card, CardContent, useMediaQuery, useTheme } from '@material-ui/core';

export const BeneficiaryCard = ({
  isPlaceholder,
  beneficiary = null,
}: IBeneficiaryProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg')); // < 1024px
  const isXSmall = useMediaQuery(theme.breakpoints.down('md')); // < 512px

  if (isPlaceholder) {
    return <BecomeBeneficiaryCard />;
  }

  if (!beneficiary) {
    return null;
  }

  // Calculate logo size based on screen size
  const logoSize = isXSmall ? 90 : isSmall ? 115 : 130;

  return (
    <Card
      elevation={0}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        height: '100%',
        width: '100%'
      }}
    >
      <CardContent style={{
        padding: '0',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img
          src={beneficiary.logo}
          alt={`${beneficiary.beneficiaryName} logo`}
          style={{
            maxWidth: `${logoSize}px`,
            maxHeight: `${logoSize}px`,
            width: 'auto',
            height: 'auto',
            display: 'block'
          }}
        />
      </CardContent>
    </Card>
  );
};
