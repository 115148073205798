import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0),
      }
    },

    container: {
      backgroundImage: "url('/images/background/what_is_it.png')",
      backgroundSize: "100% 100%",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      minHeight: "120vh",
      width: "100%",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "visible",
      marginTop: "-80px",
      paddingBottom: "50px",
      [theme.breakpoints.between('sm', 'md')]: {
        backgroundImage: "url('/images/background/medium/what_is_it.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "100vh",
      },
      [theme.breakpoints.down('sm')]: {
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "100vh",
      },
      [theme.breakpoints.down('xs')]: {
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "auto",
        paddingTop: "100px",
        paddingBottom: "50px",
      },
    },

    contentWrapper: {
      textAlign: "center",
      zIndex: 5,
      maxWidth: "1200px",
      width: "100%",
      paddingTop: "0",
      marginTop: "-100px",
      [theme.breakpoints.down('sm')]: {
        marginTop: "-50px",
        paddingTop: "30px",
      },
    },

    title: {
      color: "#ffffff",
      fontWeight: "bold",
      fontStyle: "italic",
      marginBottom: theme.spacing(2),
      fontSize: "2.8rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: "2.2rem",
      },
    },

    greenText: {
      color: "#4CAF50",
    },

    subtitle: {
      color: "#ffffff",
      marginBottom: theme.spacing(5),
      maxWidth: "700px",
      margin: "0 auto",
      fontSize: "1.1rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.9rem",
        padding: "0 16px",
      },
    },

    donationCardsWrapper: {
      position: "relative",
      width: "100%",
      height: "550px",
      [theme.breakpoints.down('sm')]: {
        height: "450px",
      },
    },

    donationCard: {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "#ffffff",
      padding: theme.spacing(2, 3),
      borderRadius: "10px",
      fontSize: "1rem",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      backdropFilter: "blur(5px)",
      transition: "box-shadow 0.3s ease",
      "&:hover": {
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.6)",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.9rem",
        padding: theme.spacing(1.5, 2),
      },
    },

    decorativeCircle1: {
      position: "absolute",
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      border: "2px solid #4CAF50",
      top: "15%",
      left: "15%",
      zIndex: 1,
    },

    decorativeCircle2: {
      position: "absolute",
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      backgroundColor: "#4CAF50",
      top: "40%",
      right: "15%",
      zIndex: 1,
    },

    decorativeCircle3: {
      position: "absolute",
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      border: "2px solid #4CAF50",
      bottom: "20%",
      left: "20%",
      zIndex: 1,
    },

    decorativeLine1: {
      position: "absolute",
      width: "100px",
      height: "2px",
      backgroundColor: "#ffffff",
      top: "25%",
      right: "20%",
      transform: "rotate(45deg)",
      zIndex: 1,
    },

    decorativeLine2: {
      position: "absolute",
      width: "100px",
      height: "2px",
      backgroundColor: "#ffffff",
      bottom: "30%",
      left: "35%",
      transform: "rotate(-45deg)",
      zIndex: 1,
    },
  })
);
