import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from "@material-ui/core";
import { INWIN_BENE_SIGNUP_URL } from "config";
import { useStyles } from "./styles";

export const BecomeBeneficiaryCard = () => {
  const styles = useStyles();

  return (
    <Card className={styles.becomeBeneficiaryCard}>
      <Link
        color={"inherit"}
        underline={"none"}
        target={"_blank"}
        rel={"noopener noreferrer"}
        href={INWIN_BENE_SIGNUP_URL}
        style={{ height: '100%', display: 'block' }}
      >
        <CardActionArea style={{ height: '100%' }}>
          <CardContent>
            <Box textAlign={"left"}>
              <Typography variant="body2">
                <strong>Become a Beneficiary</strong>
              </Typography>
              <Typography variant={"caption"}>
                and start receiving donations!
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};
