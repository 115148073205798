import React from "react";
import {
  Box,
  Card,
  Typography,
  CardContent,
} from "@material-ui/core";

interface TournamentCardProps {
  type: string;
  logo?: string;
  name?: string;
}

export const TournamentCard = ({ type, logo, name }: TournamentCardProps) => {
  const formatTournamentName = (name: string = '') => {
    const mainName = name.split(' 20')[0];
    return mainName;
  };

  return (
    <Card elevation={0} style={{
      width: '100%',
      backgroundColor: 'transparent',
      margin: '0 auto',
      maxWidth: '120px',
      overflow: 'hidden',
      cursor: 'default',
      border: 'none'
    }}>
      <CardContent style={{
        padding: '8px 0 8px 8px',
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent'
      }}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            width: '100%',
            position: 'relative',
            gap: '6px',
            backgroundColor: 'transparent'
          }}
        >
          <img
            src={logo}
            alt={`${type} logo`}
            style={{
              width: '42px',
              height: '42px',
              objectFit: 'contain',
              marginRight: '4px',
              flexShrink: 0,
              backgroundColor: 'transparent'
            }}
          />
          <Typography
            variant="h6"
            style={{
              fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 500,
              fontSize: '0.95rem',
              position: 'absolute',
              left: '42px',
              right: 0,
              whiteSpace: 'nowrap',
              color: '#ffffff',
              backgroundColor: 'transparent'
            }}
          >
            {formatTournamentName(name) || type}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
