import React from "react";
import { Card, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./styles";
import { ISponsorCardProps } from "./ISponsorCardProps";
import { BecomeSponsor } from "./becomeSponsor";
import { DefaultSponsor } from "./defaultSponsor";

export const SponsorCard = ({ isPlaceholder, sponsor }: ISponsorCardProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      item
      xs={isSmall ? 6 : 12}
      sm={6}
      md={4}
      className={styles.cardSponsor}
      style={isSmall ? { paddingRight: isPlaceholder ? 0 : 4, paddingLeft: !isPlaceholder ? 0 : 4 } : {}}
    >
      <Card
        elevation={0}
        {...(isPlaceholder && { className: styles.becomeSponsorCard })}
        style={{
          backgroundColor: isPlaceholder ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
          borderRadius: isSmall ? '10px' : '16px',
          boxShadow: 'none',
          border: isPlaceholder ? (isSmall ? '0.5px solid #4CAF50' : '1px solid #4CAF50') : 'none',
          padding: isSmall && isPlaceholder ? '4px' : 'inherit',
          width: isSmall && isPlaceholder ? 'calc(100% - 8px)' : 'auto',
          maxWidth: isSmall && isPlaceholder ? 'none' : 'auto',
          height: isSmall ? '60px' : 'auto',
          display: isSmall ? 'flex' : 'block',
          alignItems: isSmall ? 'center' : 'initial',
          justifyContent: isSmall ? 'center' : 'initial',
          overflow: 'visible'
        }}
      >
        {isPlaceholder ? (
          <BecomeSponsor />
        ) : (
          <DefaultSponsor sponsor={sponsor} />
        )}
      </Card>
    </Grid>
  );
};
