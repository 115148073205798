import React from "react";
import {
  Toolbar,
  Typography,
  Link,
  Box,
} from "@material-ui/core";

import PP from "docs/InWin_PrivacyPolicy_v1.3.pdf";
import TermsCond from "docs/InWin_T&C_v1.3.pdf";
import { useStyles } from "./styles";

export const Footer = () => {
  const styles = useStyles();

  return (
    <>
      <footer color="inherit" className={styles.appBar}>
        <Toolbar className={styles.toolbar}>
          <Box className={styles.footerContent}>
            <Link href={PP} target="_blank" className={styles.footerLink}>
              Privacy Policy
            </Link>
            <Typography
              className={styles.separator}
              color="inherit"
            >
              |
            </Typography>
            <Link href={TermsCond} target="_blank" className={styles.footerLink}>
              Terms and Conditions
            </Link>
            <Typography
              className={styles.copyright}
              color="inherit"
            >
              © InWin Corporation 2025
            </Typography>
          </Box>
        </Toolbar>
      </footer>
    </>
  );
};
