import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      minHeight: "150vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(6),
      paddingBottom: "200px",
      paddingTop: "80px",
      backgroundImage: "url('/images/background/how_it_works.png')",
      backgroundSize: "100% 100%",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.between('sm', 'md')]: {
        paddingLeft: theme.spacing(3),
        alignItems: "flex-start",
        paddingTop: theme.spacing(10),
        paddingBottom: "240px",
        minHeight: "180vh",
        backgroundImage: "url('/images/background/medium/how_it_works.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        alignItems: "flex-start",
        paddingTop: theme.spacing(10),
        paddingBottom: "160px",
        minHeight: "170vh",
        backgroundImage: "url('/images/background/small/how_it_works.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(20),
        minHeight: "180vh",
        backgroundImage: "url('/images/background/small/how_it_works.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      },
    },

    contentWrapper: {
      textAlign: "left",
      zIndex: 5,
      maxWidth: "50%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      position: "relative",
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        maxWidth: "90%",
        marginBottom: theme.spacing(15),
      },
    },

    headerSection: {
      marginBottom: theme.spacing(2),
    },

    title: {
      color: "#ffffff",
      fontWeight: "bold",
      fontStyle: "italic",
      marginBottom: theme.spacing(1),
      fontSize: "2.6rem",
      lineHeight: 1.1,
      [theme.breakpoints.down('sm')]: {
        fontSize: "2.2rem",
      },
    },

    greenText: {
      color: "#4CAF50",
    },

    subtitle: {
      color: "#ffffff",
      marginBottom: theme.spacing(3),
      maxWidth: "700px",
      fontSize: "0.95rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.85rem",
      },
    },

    cardsSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },

    infoCard: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "#ffffff",
      padding: theme.spacing(2, 3),
      borderRadius: "10px",
      fontSize: "0.85rem",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      backdropFilter: "blur(5px)",
      "&:nth-child(1)": {
        backgroundColor: "rgba(32, 32, 32, 0.9)",
      },
      "&:nth-child(2)": {
        backgroundColor: "rgba(32, 32, 32, 0.95)",
      },
      "&:nth-child(3)": {
        backgroundColor: "rgba(32, 32, 32, 1)",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.75rem",
        padding: theme.spacing(1.5, 2),
      },
    },

    bottomSection: {
      display: "flex",
      width: "100%",
      position: "relative",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(10),
      [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: "column",
        gap: theme.spacing(6),
        width: "100%",
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        gap: theme.spacing(3),
      },
    },

    featureBox: {
      maxWidth: "48%",
      display: "flex",
      alignItems: "flex-start",
      gap: theme.spacing(2),
    },

    engagementBox: {
      maxWidth: "55%",
      display: "flex",
      alignItems: "flex-start",
      gap: theme.spacing(2),
      position: "relative",
      left: "0",
      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: "80%",
        width: "80%",
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: "100%",
        width: "100%",
      },
    },

    charityBox: {
      maxWidth: "55%",
      display: "flex",
      alignItems: "flex-start",
      gap: theme.spacing(2),
      position: "relative",
      marginLeft: "auto",
      marginRight: "-30%",
      [theme.breakpoints.between('sm', 'md')]: {
        position: "relative",
        marginRight: "0",
        maxWidth: "80%",
        width: "80%",
        marginLeft: 0,
      },
      [theme.breakpoints.down('sm')]: {
        position: "relative",
        marginRight: "0",
        maxWidth: "100%",
      },
    },

    featureIcon: {
      color: "#ffffff",
      marginBottom: 0,
      marginTop: theme.spacing(4),
    },

    featureTitle: {
      color: "#ffffff",
      fontSize: "1.4rem",
      fontWeight: "bold",
      fontStyle: "italic",
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.2rem",
      },
    },

    featureDescription: {
      color: "#ffffff",
      fontSize: "0.85rem",
      lineHeight: "1.4",
      width: "100%",
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: "0.85rem",
        width: "250%",
        maxWidth: "none",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.85rem",
        width: "100%",
        maxWidth: "none",
        lineHeight: "1.3",
        letterSpacing: "-0.01em",
      },
    },

    decorativeCircle: {
      position: "absolute",
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      border: "2px solid #4CAF50",
      bottom: "20%",
      right: "40%",
      zIndex: 1,
    },
  })
);
