import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useStyles } from "./styles";
import { INWIN_PORTAL_SIGNUP_URL } from "config";

export const WhyItWorks = (props: RouteComponentProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.headerSection}>
          <Typography variant="h1" className={styles.title}>
            Why <span className={styles.greenText}>It Works!</span>
          </Typography>

          <Typography variant="h5" className={styles.subtitle}>
            Result-based crowdfunding. Support your idols' favorite charity
            through their performance.
          </Typography>

          <Button
            variant="contained"
            className={styles.joinButton}
            href={INWIN_PORTAL_SIGNUP_URL}
          >
            Join For Free
          </Button>
        </div>

        <Grid container spacing={4} className={styles.cardsContainer}>
          <Grid item xs={12} md={6}>
            <div className={styles.card}>
              <Typography variant="h3" className={styles.cardTitle}>
                Fans Want To Support Sports
              </Typography>

              <div className={styles.cardItem}>
                <FavoriteIcon className={styles.cardIcon} />
                <div className={styles.cardContent}>
                  <Typography>
                    The Crawford High School Cross Country Team raised <span className={styles.greenText}>$9,000</span> from Crawford Alumni for running
                    shoes, entry fees, and travel expenses – <span className={styles.greenText}>With InWin</span>,
                    you can help kids earn more donations by excelling
                    at their sport!
                  </Typography>
                </div>
              </div>

              <div className={styles.cardItem}>
                <FavoriteIcon className={styles.cardIcon} />
                <div className={styles.cardContent}>
                  <Typography>
                    Fans of the Jamaican bobsled team raised <span className={styles.greenText}>$120,000</span>
                    to help them go to the Olympics – Help
                    underfunded athletes realize their dreams by
                    encouraging their performance!
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={styles.card}>
              <Typography variant="h3" className={styles.cardTitle}>
                The Gamification Of Giving
              </Typography>

              <div className={styles.cardItem}>
                <FavoriteIcon className={styles.cardIcon} />
                <div className={styles.cardContent}>
                  <Typography>
                    Nothing but Nets: Stephen Curry sends three
                    malaria-preventing mosquito nets to Africa for
                    every 3-pointer he makes — You can pledge a net
                    for every three pointer too!
                  </Typography>
                </div>
              </div>

              <div className={styles.cardItem}>
                <FavoriteIcon className={styles.cardIcon} />
                <div className={styles.cardContent}>
                  <Typography>
                    LeBron James spends at least <span className={styles.greenText}>$1M</span> every year on
                    educational initiatives; he also provides 1,100 kids
                    from his home-town of Akron, OH with college
                    scholarships, totaling <span className={styles.greenText}>$41.8M</span> — Support LeBron's
                    efforts by pledging money to these causes based
                    on his performance. Donate $1 for every Lakers win,
                    every point he scores, or every assist!
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
