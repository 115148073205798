import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { ILogoProps } from "./types/ILogoProps";

const getSize = ({ size, theme }: ILogoProps) => {
  switch (size) {
    case "sm":
      return `${theme?.spacing(6)}px`;
    case "md":
    default:
      return `${theme?.spacing(18)}px`;
  }
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: getSize,
      height: getSize,
    },

    img: {
      width: theme.spacing(4),
      height: theme.spacing(3),
    },
  })
);
