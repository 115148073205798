import { makeStyles, createStyles, Theme } from "@material-ui/core";

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },

    toolbarBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 1),
      },
    },

    box: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },

    logoContainer: {
      display: "flex",
      alignItems: "center",
      background: "transparent",
      [theme.breakpoints.down('sm')]: {
        transform: 'scale(0.8)',
        transformOrigin: 'left center',
        marginLeft: theme.spacing(-1),
      },
    },

    logoBox: {
      display: "flex",
      alignItems: "center",
      background: "transparent",
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(-0.5),
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(-1),
      },
    },

    smallLogo: {
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        transform: 'translateY(-6px)',
        '& img': {
          width: theme.spacing(3),
          height: theme.spacing(2.5),
        }
      },
    },

    logoText: {
      marginLeft: theme.spacing(1),
      fontWeight: 700,
      color: "#ffffff",
      fontStyle: "italic",
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        fontWeight: 700,
        marginLeft: theme.spacing(0.5),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.9rem',
      },
    },

    // Navigation links in top bar
    navLinks: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        display: "none",
      },
    },

    navLink: {
      color: "#ffffff",
      margin: theme.spacing(0, 1),
      textTransform: "none",
      fontWeight: 500,
      "&:hover": {
        color: "#4CAF50",
      },
    },

    authButtons: {
      display: "flex",
      gap: theme.spacing(2),
      marginRight: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        marginRight: theme.spacing(-1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
      },
      [theme.breakpoints.down('xs')]: {
        gap: theme.spacing(0.5),
        marginRight: theme.spacing(-2),
      },
    },

    loginButton: {
      borderRadius: 30,
      padding: theme.spacing(0.5, 3),
      borderColor: "rgba(255, 255, 255, 0.5)",
      color: "#ffffff",
      textTransform: "none",
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.1, 0.8),
        borderColor: "#ffffff",
        color: "#ffffff",
        backgroundColor: "transparent",
        minWidth: "50px",
        fontSize: '0.7rem',
        height: "24px",
        borderRadius: 12,
        fontWeight: 500,
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.1, 0.8),
        fontSize: '0.65rem',
        minWidth: "50px",
      },
    },

    joinButton: {
      borderRadius: 30,
      padding: theme.spacing(0.5, 3),
      backgroundColor: "#4CAF50",
      color: "#000000",
      textTransform: "none",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#388E3C",
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.1, 0.8),
        backgroundColor: "#4CAF50",
        color: "#000000",
        minWidth: "80px",
        fontSize: '0.7rem',
        height: "24px",
        fontWeight: 700,
        textTransform: "none",
        borderRadius: 12,
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.1, 0.8),
        fontSize: '0.65rem',
        minWidth: "75px",
        fontWeight: 700,
      },
    },

    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },

    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      backdropFilter: "blur(10px)",
      WebkitBackdropFilter: "blur(10px)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      [theme.breakpoints.down('sm')]: {
        height: "56px",
      },
    },

    appsContainer: {
      marginTop: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 900,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
        maxWidth: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 1),
      }
    },

    appsIcon: {
      maxHeight: theme.spacing(5),
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
      transition: 'all 0.2s ease-in-out',
      borderRadius: theme.spacing(1),
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.4)',
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: theme.spacing(4),
      }
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    drawerPaper: {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: "transparent",
      },
      width: drawerWidth,
    },

    // Main content that replaced the content class
    mainContent: {
      flexGrow: 1,
      padding: 0,
      margin: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },

    // Container for all sections
    sectionsContainer: {
      padding: 0,
      margin: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    // Section style for scrollable sections
    section: {
      width: "100%",
      padding: 0,
      margin: 0,
      position: "relative",
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0,
        marginBottom: 0,
      },
    },
  })
);
