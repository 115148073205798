import React from "react";
import {
  useTheme,
  useMediaQuery,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";

import { INWIN_PORTAL_SIGNUP_URL, INWIN_PORTAL_LOGIN_URL } from "config";
import { Logo } from "view/shared/components";

import { WhatIsIt } from "../whatisit";
import { HowItWorks } from "../howitworks";
import { WhyItWorks } from "../whyitworks";
import { Beneficiaries } from "../beneficiaries";
import { NeedsDrivers } from "../needsdrivers";
import { useStyles } from "./styles";
import { Footer } from "../footer";

export const Main = () => {
  const theme = useTheme();
  const styles = useStyles();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <AppBar position="fixed" color="inherit" className={styles.appBar}>
        <Toolbar>
          <Box className={styles.toolbarBox}>
            <Typography variant="h6" noWrap className={styles.logoContainer}>
              <Box className={styles.logoBox}>
                <div className={styles.smallLogo}>
                  <Logo size="sm" />
                </div>
                <Typography variant="h5" className={styles.logoText}>
                  InWin
                </Typography>
              </Box>
            </Typography>

            <Box className={styles.authButtons}>
              <Button
                variant="outlined"
                className={styles.loginButton}
                href={INWIN_PORTAL_LOGIN_URL}
                size={small ? "small" : "medium"}
              >
                {small ? "Log in" : "Log in"}
              </Button>
              <Button
                variant="contained"
                className={styles.joinButton}
                href={INWIN_PORTAL_SIGNUP_URL}
                size={small ? "small" : "medium"}
              >
                {small ? "Join for free" : "Join for free"}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <main className={styles.mainContent}>
        {/* Toolbar for spacing below app bar */}
        <div className={styles.toolbar} style={{
          minHeight: small ? '56px' : '64px',
          height: small ? '56px' : '64px',
          padding: 0,
          margin: 0
        }} />

        <Box className={styles.sectionsContainer}>
          {/* Home/Beneficiaries section */}
          <div className={styles.section}>
            <Beneficiaries />
          </div>

          {/* What is it section */}
          <div className={styles.section}>
            <WhatIsIt />
          </div>

          {/* How it works section */}
          <div className={styles.section}>
            <HowItWorks />
          </div>

          {/* Why it works section */}
          <div className={styles.section}>
            <WhyItWorks />
          </div>

          {/* Needs drivers section */}
          <div className={styles.section}>
            <NeedsDrivers />
            <Footer />
          </div>
        </Box>
      </main>
    </>
  );
};
