import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Typography, Button, Link } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as api from "application/statistics.api";
import { useStyles } from "./styles";
import {
  SponsorCard,
  TournamentCard,
  BeneficiaryCard,
  Image,
} from "../../shared/components";
import { IBeneficiaryData } from '../../shared/types/beneficiary';
import { ITournamentData } from '../../shared/types/tournament';
import { INWIN_PORTAL_LOGIN_URL, INWIN_PORTAL_SIGNUP_URL, INWIN_BENE_SIGNUP_URL, INWIN_SPONSOR_SIGNUP_URL } from "config";

// Function to estimate tournament end date based on tournament name and type
const estimateTournamentEndDate = (tournament: ITournamentData): Date | null => {
  if (!tournament.startTime) return null;

  const startDate = new Date(tournament.startTime);
  const tournamentName = tournament.tournamentName.toLowerCase();
  const sportType = tournament.sportType.toLowerCase();

  // Extract season years if present (e.g., "2024-25")
  const seasonRegex = /(\d{4})-(\d{2})/;
  const seasonMatch = tournamentName.match(seasonRegex);

  // Default to 6 months after start date
  let endDate = new Date(startDate);
  endDate.setMonth(endDate.getMonth() + 6);

  // Specific end dates based on league
  if (tournamentName.includes('nba') || sportType === 'basketball') {
    // NBA regular season typically ends in April
    if (seasonMatch) {
      const endYear = parseInt(seasonMatch[1]) + 1;
      endDate = new Date(`April 15, ${endYear}`);
    }
  } else if (tournamentName.includes('nhl') || sportType === 'hockey') {
    // NHL regular season typically ends in April
    if (seasonMatch) {
      const endYear = parseInt(seasonMatch[1]) + 1;
      endDate = new Date(`April 10, ${endYear}`);
    }
  } else if (tournamentName.includes('mlb') || sportType === 'baseball') {
    // MLB regular season typically ends in October
    if (seasonMatch) {
      endDate = new Date(`October 1, ${seasonMatch[1]}`);
    }
  }

  return endDate;
};

export const Beneficiaries = (props: RouteComponentProps) => {
  const styles = useStyles();
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiaryData[]>([]);
  const [sponsor, setSponsor] = useState({});
  const [tournaments, setTournaments] = useState<ITournamentData[]>([]);

  useEffect(() => {
    api.getStatisticBeneficiaries().then(setBeneficiaries);
    api.getStatisticSponsors().then(setSponsor);
    api.getActiveTournaments().then(setTournaments);
  }, []);

  return (
    <Box className={styles.container}>
      <Box className={styles.heroSection}>
        <Typography variant="h1" className={styles.heroTitle}>
          Influence. Win!
        </Typography>

        <Button
          variant="contained"
          className={styles.joinButton}
          href={INWIN_PORTAL_SIGNUP_URL}
        >
          Join For Free
        </Button>

        <Box className={styles.loginText}>
          Already have an account?
          <Link
            href={INWIN_PORTAL_LOGIN_URL}
            className={styles.loginLink}
          >
            &nbsp;Log in
          </Link>
        </Box>

        <Box className={styles.appDownload}>
          <Typography variant="body1">
            You can also:
          </Typography>
          <Link
            color={"inherit"}
            underline={"none"}
            target={"_blank"}
            rel={"noopener noreferrer"}
            href={"https://play.google.com/store/apps/details?id=com.discotech.inwin"}
            className={styles.appStoreLink}
          >
            <Image ns="apps" name="googleplaystore" className={styles.appStoreIcon} />
          </Link>
        </Box>
      </Box>

      <Box className={styles.tournamentPanel}>
        <Box className={styles.tournamentTitle}>
          <Typography className={styles.tournamentTitleText}>
            <span className={styles.redDot}></span>
            <span style={{ fontStyle: 'italic' }}>Live Tournaments</span>
          </Typography>
          <Box className={styles.navArrows}>
            <ChevronLeftIcon className={styles.navArrow} />
            <ChevronRightIcon className={styles.navArrow} />
          </Box>
        </Box>

        <Box className={styles.tournamentList}>
          {tournaments.map((tournament) => (
            <Box key={tournament.id} className={styles.tournamentItem}>
              <TournamentCard
                type={tournament.sportType}
                logo={tournament.logo}
                name={tournament.tournamentName}
              />

              <Box className={styles.tournamentInfo}>
                <Typography className={styles.tournamentName}>
                  {tournament.tournamentName}
                </Typography>
                <Typography className={styles.tournamentDate}>
                  {tournament.startTime ?
                    `${new Date(tournament.startTime).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })} - ${estimateTournamentEndDate(tournament)?.toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    }) || 'TBD'}`
                    : 'TBD'
                  }
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        textAlign="center"
        className={styles.root}
        style={{
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          padding: 0
        }}
      >
        {/* Beneficiaries Section with background image */}
        <Box className={styles.beneficiariesSection}>
          <Typography
            variant="h3"
            className={styles.beneficiariesTitle}
            style={{ fontStyle: 'italic', fontSize: '2.2rem' }}
          >
            Beneficiaries
          </Typography>

          <Typography
            variant="h6"
            className={styles.beneficiariesSubtitle}
          >
            Become A Beneficiary and start receiving donations!
          </Typography>

          <Button
            variant="contained"
            className={styles.beneficiaryJoinButton}
            href={INWIN_BENE_SIGNUP_URL}
            style={{ fontSize: '0.85rem', padding: '6px 18px' }}
          >
            Become A Beneficiary
          </Button>

          <Box className={styles.beneficiaryCardsContainer}>
            {beneficiaries.map((beneficiary, index) => (
              <div
                key={beneficiary.id || index}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden'
                }}
              >
                <BeneficiaryCard
                  beneficiary={beneficiary}
                />
              </div>
            ))}
          </Box>
        </Box>

        {/* Sponsors section with background image */}
        <Box className={styles.sponsorsSection}>
          <Typography
            variant="h3"
            className={styles.sponsorsTitle}
          >
            Partners
          </Typography>

          <Typography
            variant="h6"
            className={styles.sponsorsSubtitle}
          >
            Convert your marketing budget into Charity Contributions.<br />
            Let your Consumers Donate your money on your behalf.
          </Typography>

          <Button
            variant="contained"
            className={styles.sponsorJoinButton}
            href={INWIN_SPONSOR_SIGNUP_URL}
          >
            Become a Marketing Partner
          </Button>

          <Box className={styles.sponsorCardsContainer}>
            <SponsorCard sponsor={sponsor} isPlaceholder={false} />
            <SponsorCard sponsor={sponsor} isPlaceholder={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
