import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundImage: "url('/images/background/why_it_works.png')",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      minHeight: "150vh",
      width: "100%",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(4, 0),
      paddingTop: "80px",
      paddingBottom: "120px",
      overflow: "visible",
      [theme.breakpoints.between('sm', 'md')]: {
        backgroundImage: "url('/images/background/medium/why_it_works.png')",
        minHeight: "100vh",
        paddingBottom: theme.spacing(8),
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: "100vh",
        paddingBottom: theme.spacing(8),
      },
      [theme.breakpoints.down('xs')]: {
        backgroundAttachment: "scroll",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "auto",
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      }
    },

    contentWrapper: {
      width: "90%",
      maxWidth: "1200px",
      margin: "0 auto",
      zIndex: 2,
    },

    headerSection: {
      textAlign: "center",
      marginBottom: theme.spacing(6),
    },

    title: {
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: "2.6rem",
      marginBottom: theme.spacing(2),
      fontStyle: "italic",
      [theme.breakpoints.down('sm')]: {
        fontSize: "2rem",
      },
    },

    greenText: {
      color: "#4CAF50",
    },

    subtitle: {
      color: "#ffffff",
      fontSize: "0.9rem",
      marginBottom: theme.spacing(4),
      maxWidth: "800px",
      margin: "0 auto 2rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.8rem",
      },
    },

    joinButton: {
      backgroundColor: "#4CAF50",
      color: "#000000",
      padding: theme.spacing(1, 4),
      borderRadius: 50,
      fontSize: "1rem",
      fontWeight: "bold",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#388E3C",
      },
    },

    cardsContainer: {
      marginTop: theme.spacing(4),
    },

    card: {
      backgroundColor: "rgba(32, 32, 32, 0.9)",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },

    cardTitle: {
      color: "#ffffff",
      fontSize: "1.3rem",
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
      fontStyle: "italic",
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.1rem",
      },
    },

    cardItem: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "flex-start",
    },

    cardIcon: {
      color: "#ffffff",
      fontSize: 28,
      marginTop: theme.spacing(0.5),
    },

    cardContent: {
      color: "#ffffff",
      "& p": {
        color: "#ffffff",
        fontSize: "0.8rem",
        lineHeight: 1.6,
      },
    },

    // Keeping some of the original styles for compatibility
    grid: {
      height: "auto"
    },

    paper: {
      padding: theme.spacing(1),
      textAlign: "left",
    },

    timeline: {
      padding: theme.spacing(1),
      margin: 0,
      height: '100%',
      '& .MuiTimelineItem-missingOppositeContent:before': {
        display: 'none'
      }
    },

    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },

    ctaSection: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },

    appsIcon: {
      maxHeight: theme.spacing(6),
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
      transition: 'all 0.2s ease-in-out',
      borderRadius: theme.spacing(1.25),
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.4)',
      }
    },
  })
);
