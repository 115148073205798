export const API_URL =
  process.env.FORM_API_URL || "https://app.99inbound.com/api/e/DoOOoEJJ";

export const INTRO_TIMEOUT = 3000;

export const INWIN_PORTAL_SIGNUP_URL =
  "https://app.inwin.online/fe/auth/signup";

export const INWIN_PORTAL_LOGIN_URL =
  "https://app.inwin.online/fe/auth/login";

export const INWIN_BENE_SIGNUP_URL =
  "https://app.inwin.online/fe/auth/pre-signup-beneficiary";

export const INWIN_SPONSOR_SIGNUP_URL =
  "https://app.inwin.online/fe/auth/pre-signup-partner";

export const INWIN_PUBLIC_RESOURCES =
  "https://app.inwin.online/public/";

export const STORAGE_KEYS = Object.freeze({
  INTRO: "inwinland:intro",
});
