import React from "react";
import {
  Box,
  CardMedia,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./styles";

export const BecomeSponsor = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box className={styles.cardActionArea} style={{ backgroundColor: 'transparent', cursor: 'default' }}>
        <Box
          display="flex"
          flexDirection={isSmall ? "row" : "column"}
          alignItems="center"
          justifyContent={isSmall ? "flex-start" : "center"}
          textAlign="center"
          style={{ gap: isSmall ? '4px' : '4px' }}
        >
          <CardMedia
            className={styles.cardMediaPlaceholder}
            image={"/images/sponsor_placeholder.png"}
            style={{
              backgroundColor: 'transparent',
              marginBottom: 0,
              height: isSmall ? '30px' : '50px',
              width: isSmall ? '30px' : '140px'
            }}
          />
          <Typography variant="caption" style={{
            color: "#ffffff",
            fontSize: isSmall ? "0.4rem" : "0.65rem",
            lineHeight: isSmall ? '0.9' : '1.2',
            padding: 0,
            margin: 0,
            display: 'block',
            textAlign: isSmall ? 'left' : 'center',
            whiteSpace: isSmall ? 'nowrap' : 'normal'
          }}>
            Your logo can be here
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
