import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundImage: "url('/images/background/needs_and_drivers.png')",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      minHeight: "150vh",
      width: "100%",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(6, 0),
      paddingTop: "80px",
      paddingBottom: "120px",
      overflow: "visible",
      [theme.breakpoints.between('sm', 'md')]: {
        backgroundImage: "url('/images/background/medium/needs_and_drivers.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        justifyContent: "center",
        minHeight: "140vh",
        paddingBottom: "50px",
        paddingTop: "60px",
        alignItems: "flex-start",
      },
      [theme.breakpoints.down('sm')]: {
        backgroundImage: "url('/images/background/small/needs_and_drivers.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        justifyContent: "center",
        minHeight: "140vh",
        paddingBottom: "50px",
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: "url('/images/background/small/needs_and_drivers.png')",
        backgroundAttachment: "scroll",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        minHeight: "140vh",
        paddingTop: "40px",
        paddingBottom: "50px",
      },
    },

    contentWrapper: {
      width: "50%",
      marginRight: theme.spacing(1),
      zIndex: 2,
      [theme.breakpoints.between('sm', 'md')]: {
        width: "90%",
        marginRight: theme.spacing(0.5),
        padding: theme.spacing(0, 2),
        marginTop: "-50px",
      },
      [theme.breakpoints.down('sm')]: {
        width: "90%",
        marginRight: theme.spacing(0.5),
        padding: theme.spacing(0, 2),
        marginTop: "-50px",
      },
      [theme.breakpoints.down('xs')]: {
        width: "95%",
        marginRight: 0,
        padding: theme.spacing(0, 2),
        marginTop: "-60px",
      },
    },

    headerSection: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.between('sm', 'md')]: {
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
      "@media (max-width: 375px)": {
        marginBottom: theme.spacing(1),
      },
    },

    title: {
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: "2.8rem",
      marginBottom: theme.spacing(4),
      fontStyle: "italic",
      [theme.breakpoints.down('sm')]: {
        fontSize: "2.2rem",
        marginBottom: theme.spacing(2),
      },
      "@media (max-width: 375px)": {
        fontSize: "2rem",
        marginBottom: theme.spacing(1),
      },
    },

    greenText: {
      color: "#4CAF50",
    },

    joinButton: {
      backgroundColor: "#4CAF50",
      color: "#000000",
      padding: theme.spacing(1, 4),
      borderRadius: 50,
      fontSize: "0.9rem",
      fontWeight: "bold",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#388E3C",
      },
    },

    sectionsContainer: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },

    section: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.between('sm', 'md')]: {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
      "@media (max-width: 375px)": {
        marginBottom: theme.spacing(0.5),
      },
    },

    sectionTitle: {
      color: "#ffffff",
      fontSize: "1.8rem",
      fontWeight: "bold",
      marginBottom: theme.spacing(3),
      fontStyle: "italic",
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.5rem",
        marginBottom: theme.spacing(1),
      },
      "@media (max-width: 375px)": {
        fontSize: "1.2rem",
        marginBottom: theme.spacing(0.25),
      },
    },

    listContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
      "@media (max-width: 375px)": {
        gap: theme.spacing(0.25),
      },
    },

    listItem: {
      display: "flex",
      alignItems: "flex-start",
      gap: theme.spacing(2.5),
      backgroundColor: "transparent",
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1.5),
        padding: theme.spacing(0.5, 0),
      },
      "@media (max-width: 375px)": {
        gap: theme.spacing(0.5),
        padding: theme.spacing(0.1, 0),
      },
    },

    numberCircle: {
      width: "auto",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      color: "#4CAF50",
      fontSize: "1.3rem",
      flexShrink: 0,
      lineHeight: 1,
      "@media (max-width: 375px)": {
        fontSize: "1.1rem",
      },
    },

    itemText: {
      color: "#ffffff",
      fontSize: "0.85rem",
      lineHeight: 1.5,
      paddingTop: theme.spacing(0.5),
      "@media (max-width: 375px)": {
        fontSize: "0.8rem",
        lineHeight: 1.3,
        paddingTop: theme.spacing(0.25),
      },
    },

    card: {
      margin: theme.spacing(1),
    },

    avatar: {
      backgroundColor: theme.palette.primary.main,
    },

    grid: {
      height: "auto"
    },

    ctaSection: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },

    appsIcon: {
      maxHeight: theme.spacing(6),
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
      transition: 'all 0.2s ease-in-out',
      borderRadius: theme.spacing(1.25),
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.4)',
      }
    },
  })
);
