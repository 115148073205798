import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Typography,
  Button,
} from "@material-ui/core";

import { useStyles } from "./styles";
import { INWIN_PORTAL_SIGNUP_URL } from "config";

const fansDescriptionData = [
  "Deepen emotional involvement",
  "Influence your idols, the stars of sports, and worthy initiatives",
  "Become an integral part of victory – and brag about it!",
];

const profiDescriptionData = [
  "Tap additional sources of financing and remuneration",
  "Quantify engagement for specific events, charities, and sports",
  "Improve PR",
];

export const NeedsDrivers = (props: RouteComponentProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.headerSection}>
          <Typography variant="h1" className={styles.title}>
            <span className={styles.greenText}>InWin:</span> Needs And Drivers
          </Typography>

          <Button
            variant="contained"
            className={styles.joinButton}
            href={INWIN_PORTAL_SIGNUP_URL}
          >
            Join For Free
          </Button>
        </div>

        <div className={styles.section}>
          <Typography variant="h2" className={styles.sectionTitle}>
            Sports Fans
          </Typography>

          <div className={styles.listContainer}>
            {fansDescriptionData.map((text, index) => (
              <div key={index} className={styles.listItem}>
                <div className={styles.numberCircle}>
                  {index + 1}
                </div>
                <Typography className={styles.itemText}>
                  {text}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.section}>
          <Typography variant="h2" className={styles.sectionTitle}>
            Sports Professionals
          </Typography>

          <div className={styles.listContainer}>
            {profiDescriptionData.map((text, index) => (
              <div key={index} className={styles.listItem}>
                <div className={styles.numberCircle}>
                  {index + 1}
                </div>
                <Typography className={styles.itemText}>
                  {text}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
};
