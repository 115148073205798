import React from "react";
import {
  Box,
  CardActionArea,
  CardMedia,
  Link,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { IDefaultSponsorProps } from "./ISponsorCardProps";
import { INWIN_PUBLIC_RESOURCES } from "../../../../config";

export const DefaultSponsor = ({ sponsor }: IDefaultSponsorProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Link
      color={"inherit"}
      underline={"none"}
      target={"_blank"}
      rel={"noopener noreferrer"}
      href={sponsor.website}
    >
      <CardActionArea className={styles.cardActionArea} style={{ backgroundColor: 'transparent' }}>
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
          {sponsor?.logo ? (
            <CardMedia
              className={styles.cardMediaSponsor}
              image={`${INWIN_PUBLIC_RESOURCES}${sponsor.logo}`}
              style={{
                backgroundColor: 'transparent',
                height: isSmall ? '50px' : '70px',
                width: isSmall ? '130px' : '180px',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                objectFit: 'contain',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            />
          ) : (
            <Box
              className={styles.cardMediaSponsor}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="transparent"
              style={{
                color: '#ffffff'
              }}
            >
              <img
                src="/images/sponsor_placeholder.png"
                alt="No Logo"
                style={{
                  maxHeight: isSmall ? '50px' : '80px',
                  maxWidth: isSmall ? '130px' : '180px',
                  objectFit: 'contain',
                  display: 'block'
                }}
              />
            </Box>
          )}
        </Box>
      </CardActionArea>
    </Link>
  );
};
