import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Link,
    Typography,
    Snackbar,
    makeStyles,
} from "@material-ui/core";

// Import the Privacy Policy PDF
import PrivacyPolicy from "docs/InWin_PrivacyPolicy_v1.3.pdf";

// Define styles
const useStyles = makeStyles((theme) => ({
    cookieBanner: {
        backgroundColor: "#333333", // Dark grey background
        padding: theme.spacing(2, 3),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        zIndex: 1000,
        borderRadius: "8px", // Add rounded corners to the banner
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
    content: {
        flex: 1,
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            marginBottom: 0,
            marginRight: theme.spacing(2),
        },
    },
    text: {
        fontSize: "0.9rem",
        lineHeight: 1.4,
        color: "#ffffff", // White text
    },
    buttons: {
        display: "flex",
        gap: theme.spacing(2), // Increased gap between buttons
        padding: theme.spacing(1, 0),
    },
    declineButton: {
        padding: theme.spacing(0.75, 3),
        fontSize: "0.9rem",
        minWidth: "100px",
        color: "#ffffff", // White text
        backgroundColor: "transparent", // Transparent background
        border: "2px solid #ffffff", // White border
        borderRadius: "50px", // Rounded corners
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight white hover effect
        },
    },
    acceptButton: {
        padding: theme.spacing(0.75, 3),
        fontSize: "0.9rem",
        minWidth: "100px",
        color: "#000000", // Changed from white to black
        backgroundColor: "#4fd1a5", // Green background
        border: "none",
        borderRadius: "50px", // Rounded corners
        "&:hover": {
            backgroundColor: "#3cb389", // Darker green on hover
        },
    },
    link: {
        fontSize: "0.9rem",
        fontWeight: 500,
        color: "#4fd1a5", // Green color for the link
        "&:hover": {
            color: "#3cb389", // Darker green on hover
        },
    }
}));

// Cookie consent storage keys
const COOKIE_CONSENT_KEY = "cookieConsent";
const COOKIE_CONSENT_TIMESTAMP = "cookieConsentTimestamp";

// Cookie expiration in days
const COOKIE_CONSENT_EXPIRY_DAYS = 180; // 6 months

// List of non-essential tracking and analytics scripts
const NON_ESSENTIAL_SCRIPT_PATTERNS = [
    'facebook', 'fbq', 'fbevents',
    'google-analytics', 'gtag', 'ga',
    'hotjar', 'mixpanel', 'segment',
];

// Function to delete non-essential cookies
const deleteNonEssentialCookies = () => {
    const cookies = document.cookie.split(";");

    // List of essential cookies that shouldn't be deleted
    const essentialCookies = [
        'connect.sid', // Session ID
        'JSESSIONID',  // Java Session ID
        'XSRF-TOKEN',  // CSRF protection
        '__Host-csrf-token', // CSRF token
    ];

    // List of known tracking cookies to specifically target
    const trackingCookies = [
        '_fbp', 'datr', 'sb', 'wd', // Facebook
        '_ga', '_gid', '_gat', // Google Analytics
        '_hjid', '_hjSessionUser', // Hotjar
    ];

    // Try to delete first-party cookies
    cookies.forEach(cookie => {
        const cookieParts = cookie.split("=");
        const cookieName = cookieParts[0].trim();

        // If cookie is not essential, delete it
        if (!essentialCookies.includes(cookieName)) {
            // Delete from root path
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            // Also try deleting from other common paths
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname};`;
        }
    });

    // Try to delete common third-party cookies by name
    trackingCookies.forEach(cookieName => {
        // Try various domain combinations
        const domains = [
            window.location.hostname,
            `.${window.location.hostname}`,
            'facebook.com',
            '.facebook.com',
            'google.com',
            '.google.com',
            'hotjar.com',
            '.hotjar.com'
        ];

        domains.forEach(domain => {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
        });
    });
};

// Function to disable tracking scripts that might be loaded
const disableTrackingScripts = () => {
    // Set tracking objects to non-functional versions if they exist
    NON_ESSENTIAL_SCRIPT_PATTERNS.forEach(pattern => {
        // Set window tracking objects to non-functional versions if they exist
        if (pattern === 'fbq' && window.fbq) {
            window.fbq = function () { };
        }

        if (pattern === 'ga' && (window as any).ga) {
            (window as any).ga = function () { };
        }

        if (pattern === 'gtag' && (window as any).gtag) {
            (window as any).gtag = function () { };
        }
    });
};

export const CookieConsent: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // Check if user has already made a choice
        const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
        const timestamp = localStorage.getItem(COOKIE_CONSENT_TIMESTAMP);

        if (!consent || !timestamp) {
            // First time visitor or storage cleared
            setOpen(true);
            return;
        }

        // Check if consent has expired
        const consentDate = new Date(timestamp);
        const expiryDate = new Date(consentDate);
        expiryDate.setDate(expiryDate.getDate() + COOKIE_CONSENT_EXPIRY_DAYS);
        const currentDate = new Date();

        if (currentDate > expiryDate) {
            // Consent expired, ask again
            setOpen(true);
        } else if (consent === "declined") {
            // If user previously declined, ensure non-essential cookies stay disabled
            deleteNonEssentialCookies();
            disableTrackingScripts();
        }
    }, []);

    const handleAccept = () => {
        // Save user preferences
        localStorage.setItem(COOKIE_CONSENT_KEY, "accepted");
        localStorage.setItem(COOKIE_CONSENT_TIMESTAMP, new Date().toISOString());

        // Try to enable Facebook tracking if it exists
        if (typeof window.fbq === 'function') {
            try {
                window.fbq('consent', 'grant');
                window.fbq('track', 'Consent');
            } catch (e) {
                // Silently handle errors
            }
        }

        // Try to enable Google Analytics if it exists
        if (typeof (window as any).ga === 'function') {
            try {
                (window as any).ga('consent', 'update', {
                    'analytics_storage': 'granted',
                    'ad_storage': 'granted'
                });
            } catch (e) {
                // Silently handle errors
            }
        }

        // Verify cookies are being set correctly by setting a test cookie
        try {
            document.cookie = `cookie_consent_test=1; path=/; max-age=120`;
        } catch (e) {
            // Silently handle errors
        }

        // Close the banner
        setOpen(false);
    };

    const handleDecline = () => {
        // Save user preferences
        localStorage.setItem(COOKIE_CONSENT_KEY, "declined");
        localStorage.setItem(COOKIE_CONSENT_TIMESTAMP, new Date().toISOString());

        // Only allow essential cookies
        deleteNonEssentialCookies();
        disableTrackingScripts();

        // Close the banner
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            style={{
                position: "fixed",
                maxWidth: "100%",
                zIndex: 1000,
                bottom: 0, // Position at bottom of screen
            }}
        >
            <Box className={classes.cookieBanner}>
                <div className={classes.content}>
                    <Typography variant="body2" className={classes.text}>
                        We use cookies and other technologies to personalize your experience,
                        perform marketing, and collect analytics. Learn more in our{" "}
                        <Link href={PrivacyPolicy} color="primary" className={classes.link} target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </Link>
                        .
                    </Typography>
                </div>
                <div className={classes.buttons}>
                    <Button
                        variant="outlined"
                        className={classes.declineButton}
                        onClick={handleDecline}
                    >
                        Decline
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.acceptButton}
                        onClick={handleAccept}
                    >
                        Accept
                    </Button>
                </div>
            </Box>
        </Snackbar>
    );
}; 