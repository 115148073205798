import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMediaSponsor: {
      height: theme.spacing(9),
      width: theme.spacing(22),
      backgroundSize: "contain",
      backgroundColor: "transparent",
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(6),
        width: theme.spacing(16),
        marginTop: '-5px',
        padding: theme.spacing(1.5, 0),
      },
    },

    cardMediaPlaceholder: {
      height: theme.spacing(9),
      width: theme.spacing(22),
      backgroundSize: "contain",
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(4),
        width: theme.spacing(8),
        backgroundSize: "contain",
        backgroundPosition: "center"
      },
    },

    cardSponsor: {
      maxWidth: theme.spacing(36),
      backgroundColor: "transparent",
      borderRadius: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        maxHeight: theme.spacing(14),
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(20),
      },
    },

    becomeSponsorCard: {
      transition: 'all 0.2s ease-in-out',
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: theme.spacing(2),
      border: '1px solid #4CAF50',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '120px',
      }
    },

    cardActionArea: {
      display: "block",
      padding: theme.spacing(2),
      backgroundColor: "transparent",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5),
      },
    },
  })
);
