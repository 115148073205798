import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.95)",
      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
    },

    toolbar: {
      display: "flex",
      justifyContent: "center",
      minHeight: "50px",
      [theme.breakpoints.down("md")]: {
        minHeight: "40px",
        padding: "4px 0",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "30px",
        padding: "2px 0",
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        flexDirection: "column",
      },
    },

    footerContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.down("md")]: {
        transform: "scale(0.9)",
      },
      [theme.breakpoints.down("sm")]: {
        transform: "scale(0.8)",
      },
    },

    textBlock: {
      marginRight: "8px",
      marginLeft: "8px",
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },

    footerLink: {
      color: "#4CAF50",
      marginRight: "4px",
      marginLeft: "4px",
      textDecoration: "none",
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      "&:hover": {
        textDecoration: "underline",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.85rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },

    separator: {
      color: "#FFFFFF",
      margin: "0 4px",
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.down("md")]: {
        fontSize: "0.85rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
        margin: "0 2px",
      },
    },

    copyright: {
      color: "#FFFFFF",
      marginLeft: "8px",
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.down("md")]: {
        fontSize: "0.85rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
        marginLeft: "4px",
      },
    },
  })
);
