import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMediaSponsor: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      backgroundSize: "contain",
    },

    card: {
      margin: theme.spacing(0.5),
      maxWidth: theme.spacing(30),
    },

    cardMedia: {
      margin: theme.spacing(0.5),
      height: theme.spacing(10),
      backgroundSize: "contain",
    },

    cardMediaPlaceholder: {
      margin: theme.spacing(1.5),
      height: theme.spacing(6),
      width: theme.spacing(6),
      backgroundSize: "contain",
    },

    cardBeneficiary: {
      minWidth: theme.spacing(22),
      maxWidth: theme.spacing(30),
    },

    becomeBeneficiaryCard: {
      width: '200px',
      height: '90px',
      margin: '0 4px',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.35)',
      }
    },

    cardActionArea: {
      display: "block",
      padding: theme.spacing(0.75),
    },
  })
);
