import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Theme,
  Typography,
} from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import FavoriteIcon from "@material-ui/icons/Favorite";

import { useStyles } from "./styles";

export const HowItWorks = (props: RouteComponentProps & { theme?: Theme }) => {
  const styles = useStyles(props);

  return (
    <Box className={styles.container}>
      <div className={styles.decorativeCircle}></div>

      <div className={styles.contentWrapper}>
        <div className={styles.headerSection}>
          <Typography variant="h1" className={styles.title}>
            How <span className={styles.greenText}>It Works?</span>
          </Typography>

          <Typography variant="h5" className={styles.subtitle}>
            InWin allows fans to donate to charitable causes through the performance of
            their favorite athletes and teams
          </Typography>
        </div>

        <div className={styles.cardsSection}>
          <div className={styles.infoCard}>
            <Typography>
              By placing an <span className={styles.greenText}>InWin</span>, you can contribute to charities championed by sporting stars
              or sponsor a club, team, or athlete trying to make their dream come true.
            </Typography>
          </div>

          <div className={styles.infoCard}>
            <Typography>
              For example, you can pledge to <span className={styles.greenText}>donate $10</span> to the LeBron James Family Foundation
              for every triple-double King James records.
            </Typography>
          </div>

          <div className={styles.infoCard}>
            <Typography>
              Or, you can pledge $10 towards the renovation of your school's sports facilities for
              every win its teams score.
            </Typography>
          </div>
        </div>

        <div className={styles.bottomSection}>
          <div className={styles.engagementBox}>
            <div className={styles.featureIcon}>
              <PeopleIcon fontSize="large" />
            </div>
            <div>
              <Typography variant="h4" className={styles.featureTitle}>
                Engagement
              </Typography>
              <Typography className={styles.featureDescription}>
                Fans can pledge donations that depend on the performance
                of athletes and teams, increasing their engagement with the
                sport.
              </Typography>
            </div>
          </div>

          <div className={styles.charityBox}>
            <div className={styles.featureIcon}>
              <FavoriteIcon fontSize="large" />
            </div>
            <div>
              <Typography variant="h4" className={styles.featureTitle}>
                Charity
              </Typography>
              <Typography className={styles.featureDescription}>
                You can become a philanthropist benefiting preferred
                charities of your favorite athletes or helping underfinanced
                teams and sports.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
