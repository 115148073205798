import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundImage: "url('/images/background/influence_win.png')",
      backgroundSize: "cover",
      backgroundPosition: "80% -64px",
      backgroundRepeat: "no-repeat",
      minHeight: "125vh",
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: theme.spacing(6, 0, 20, 0),
      marginTop: "-64px",
      paddingTop: "128px",
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.between('sm', 'md')]: {
        backgroundImage: "url('/images/background/medium/influence_win.png')",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        paddingLeft: theme.spacing(2),
        minHeight: "100vh",
        paddingBottom: theme.spacing(10),
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      },
      [theme.breakpoints.down('md')]: {
        backgroundImage: "url('/images/background/small/influence_win.png')",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        paddingLeft: theme.spacing(2),
        minHeight: "100vh",
        paddingBottom: theme.spacing(10),
      },
      [theme.breakpoints.down('md')]: {
        backgroundImage: "url('/images/background/small/influence_win.png')",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        paddingTop: "80px",
        paddingBottom: theme.spacing(8),
      },
    },

    heroSection: {
      width: "calc(100% - 32px)",
      maxWidth: "1200px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: theme.spacing(4, 4, 6, 0),
      textAlign: "left",
      marginBottom: theme.spacing(4),
      [theme.breakpoints.between('sm', 'md')]: {
        alignItems: "center",
        padding: theme.spacing(3, 2, 4, 2),
        textAlign: "center",
        order: 1,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 4, 0),
      },
    },

    heroTitle: {
      color: "#ffffff",
      fontSize: "3.8rem",
      fontWeight: "bold",
      fontStyle: "italic",
      marginBottom: theme.spacing(5),
      marginLeft: theme.spacing(4),
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: "3rem",
        textAlign: "center",
        marginLeft: 0,
        width: "100%"
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "3rem",
      },
    },

    joinButton: {
      backgroundColor: "#4CAF50",
      color: "#000000",
      padding: theme.spacing(1, 4.5),
      fontSize: "1rem",
      borderRadius: 50,
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(4),
      textTransform: "none",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#388E3C",
      },
      [theme.breakpoints.between('sm', 'md')]: {
        marginLeft: 0,
        alignSelf: "center",
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.7, 3),
        fontSize: "0.9rem",
      },
    },

    loginText: {
      color: "#ffffff",
      fontSize: "1rem",
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(4),
      [theme.breakpoints.between('sm', 'md')]: {
        marginLeft: 0,
        alignSelf: "center",
      },
    },

    loginLink: {
      color: "#4CAF50",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },

    appDownload: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      color: "#ffffff",
      marginLeft: theme.spacing(4),
      [theme.breakpoints.between('sm', 'md')]: {
        marginLeft: 0,
        justifyContent: "center",
        alignSelf: "center",
        marginBottom: theme.spacing(3),
      },
    },

    appStoreLink: {
      display: "flex",
      alignItems: "center",
    },

    appStoreIcon: {
      height: theme.spacing(20),
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
      transition: 'all 0.2s ease-in-out',
      borderRadius: theme.spacing(1),
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.4)',
      }
    },

    root: {
      margin: theme.spacing(2),
      [theme.breakpoints.between('sm', 'md')]: {
        order: 4,
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1),
      }
    },

    grid: {
      height: "auto",
    },

    cardMedia: {
      height: theme.spacing(15),
      backgroundSize: "contain",
    },

    cardMediaTour: {
      height: theme.spacing(11),
      width: theme.spacing(20),
      backgroundSize: "contain",
    },

    card: {
      margin: theme.spacing(2),
    },

    cardTournaments: {
      minHeight: theme.spacing(16),
      minWidth: theme.spacing(51),
      [theme.breakpoints.down('md')]: {
        minWidth: 'auto',
        width: '100%'
      }
    },

    cardActionArea: {
      display: "block",
      padding: theme.spacing(2),
    },

    tournamentPanel: {
      position: "absolute",
      right: theme.spacing(40),
      top: theme.spacing(20),
      width: "420px",
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      backdropFilter: "blur(15px)",
      border: "1px solid rgba(255, 255, 255, 0.2)",
      boxShadow: "0 8px 32px rgba(31, 38, 135, 0.25), inset 0 0 80px rgba(255, 255, 255, 0.05)",
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3),
      zIndex: 10,
      [theme.breakpoints.between('sm', 'md')]: {
        position: "relative",
        right: "auto",
        top: "auto",
        width: "90%",
        margin: "20px auto",
        order: 2,
      },
      [theme.breakpoints.down('sm')]: {
        position: "relative",
        right: "auto",
        top: "auto",
        width: "90%",
        margin: "0 auto",
      },
    },

    tournamentTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },

    tournamentTitleText: {
      color: "#ffffff",
      fontSize: "1.5rem",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },

    redDot: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "#ff3e3e",
      marginRight: theme.spacing(1),
    },

    navArrows: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },

    navArrow: {
      color: "#ffffff",
      fontSize: "1.5rem",
      cursor: "pointer",
    },

    tournamentList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },

    tournamentItem: {
      backgroundColor: "rgba(32, 32, 32, 0.85)",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1.5, 1.5),
      display: "flex",
      alignItems: "center",
      gap: 0,
      "& .MuiCard-root": {
        backgroundColor: "transparent !important",
        boxShadow: "none !important",
        margin: 0,
      },
      "& img": {
        backgroundColor: "transparent !important",
      },
      "& .MuiCardContent-root": {
        backgroundColor: "transparent !important",
        padding: "0 !important",
      },
      "& .MuiCardMedia-root": {
        backgroundColor: "transparent !important",
      },
      "& div": {
        backgroundColor: "transparent !important",
      },
    },

    sportIcon: {
      width: 32,
      height: 32,
    },

    sportIconContainer: {
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: theme.spacing(-4),
    },

    tournamentInfo: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginLeft: theme.spacing(-2),
    },

    tournamentName: {
      color: "#ffffff",
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },

    tournamentDate: {
      color: "#aaaaaa",
      fontSize: "0.8rem",
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },

    tournamentLogo: {
      width: 24,
      height: 24,
    },

    beneficiariesSection: {
      backgroundImage: "url('/images/background/beneficiaries.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      width: "85%",
      maxWidth: "800px",
      margin: "0 auto",
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(4),
      minHeight: "320px",
      height: "100%",
      textAlign: "center",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.between('sm', 'md')]: {
        backgroundImage: "url('/images/background/medium/beneficiaries.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "90%",
        padding: theme.spacing(3.5, 2.5),
        minHeight: "300px",
        order: 3,
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        backgroundImage: "url('/images/background/small/beneficiaries.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "90%",
        padding: theme.spacing(3.5, 2.5),
        minHeight: "280px",
        marginTop: theme.spacing(4),
      },
    },

    beneficiariesTitle: {
      color: "#ffffff",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: "2rem",
      },
    },

    beneficiariesSubtitle: {
      color: "#ffffff",
      fontSize: "1.1rem",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.9rem",
      },
    },

    beneficiaryJoinButton: {
      backgroundColor: "#ffffff",
      color: "#000000",
      padding: theme.spacing(0.8, 2.5),
      fontSize: "0.9rem",
      borderRadius: 50,
      marginBottom: theme.spacing(3),
      textTransform: "none",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },

    beneficiaryCardsContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(130px, 1fr))",
      gap: theme.spacing(1),
      marginTop: theme.spacing(3),
      width: "100%",
      "& > div": {
        backgroundColor: "transparent !important",
        boxShadow: "none !important",
        border: "none !important",
        borderRadius: "0 !important",
        padding: "0 !important",
        margin: "0 !important",
      },
      "& .MuiCard-root": {
        backgroundColor: "transparent !important",
        boxShadow: "none !important",
        border: "none !important",
        borderRadius: "0 !important",
        padding: "0 !important",
        margin: "0 !important",
      },
      "& .MuiCardContent-root": {
        padding: "0 !important",
        backgroundColor: "transparent !important",
      },
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        padding: "0 !important"
      },
      [theme.breakpoints.up('lg')]: {
        gap: theme.spacing(1),
        width: "80%",
        display: "flex",
        justifyContent: "center",
        "& > div": {
          margin: "0 10px !important",
          display: "flex",
          justifyContent: "center",
          width: "auto",
        },
        "& img": {
          maxHeight: "75px",
          width: "auto",
        }
      },
      [theme.breakpoints.between('sm', 'md')]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: 0,
        marginTop: theme.spacing(2),
        width: "70%",
        "& > div": {
          padding: "0 !important",
          margin: "0 -5px !important",
          width: "28%",
          display: "flex",
          justifyContent: "center",
        },
        "& .MuiCard-root": {
          margin: "0 -5px !important",
          maxWidth: "100%",
        },
        "& img": {
          maxWidth: "85%",
          maxHeight: "60px",
          padding: "0 !important"
        }
      },
      [theme.breakpoints.down('sm')]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        width: "85%",
        margin: "20px auto 0",
        gap: theme.spacing(3),
        "& > div": {
          padding: 0,
          margin: 0,
          width: "auto",
          flex: "0 0 auto",
        },
        "& .MuiCard-root": {
          padding: 0,
          margin: 0,
          width: "auto",
        },
        "& img": {
          width: "auto",
          height: "40px",
          maxHeight: "40px",
        }
      },
    },

    sponsorsSection: {
      backgroundImage: "url('/images/background/sponsors.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      width: "85%",
      maxWidth: "800px",
      margin: "0 auto",
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(3),
      minHeight: "320px",
      height: "100%",
      textAlign: "center",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.between('sm', 'md')]: {
        backgroundImage: "url('/images/background/medium/sponsors.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "90%",
        padding: theme.spacing(3.5, 2.5),
        minHeight: "300px",
        order: 5,
      },
      [theme.breakpoints.down('sm')]: {
        backgroundImage: "url('/images/background/small/sponsors.png')",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "95%",
        padding: theme.spacing(3.5, 2.5),
        minHeight: "280px",
      },
    },

    sponsorsTitle: {
      color: "#ffffff",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
      fontStyle: "italic",
      [theme.breakpoints.down('sm')]: {
        fontSize: "2rem",
        marginTop: theme.spacing(0.25),
      },
    },

    sponsorsSubtitle: {
      color: "#ffffff",
      fontSize: "1.1rem",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.9rem",
      },
    },

    sponsorJoinButton: {
      backgroundColor: "#ffffff",
      color: "#000000",
      padding: theme.spacing(0.8, 2.5),
      fontSize: "0.9rem",
      borderRadius: 50,
      marginBottom: theme.spacing(3),
      textTransform: "none",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },

    sponsorCardsContainer: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginTop: theme.spacing(3),
      width: "100%",
      flexWrap: "wrap",
      "& .sponsor-name, & .sponsorName, & .sponsor-title, & .sponsorTitle, & h5, & h6, & .MuiTypography-h5, & .MuiTypography-h6": {
        display: "none !important",
      },
      "& .sponsor-info .name-container, & .sponsorInfo .nameContainer": {
        display: "none !important",
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(1),
        padding: theme.spacing(1, 0),
        "& img": {
          maxHeight: "70px",
          objectFit: "contain",
          margin: theme.spacing(1, 0),
        }
      },
    },
  })
);
