import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

// Create a base theme with custom breakpoints
const baseTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#F09745",
    },
    secondary: {
      main: "#E5E7EC",
    },
    divider: "#F09745",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,     // 0-511px
      md: 512,   // 512-1023px
      lg: 1025,  // 1024px+
      xl: 1920,
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    // Add responsive font sizes
    h1: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '2.5rem',
      '@media (min-width:512px)': {
        fontSize: '3rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '3.5rem',
      },
    },
    h2: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '2rem',
      '@media (min-width:512px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '3rem',
      },
    },
    h5: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '1.2rem',
      '@media (min-width:512px)': {
        fontSize: '1.4rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '1rem',
      '@media (min-width:1024px)': {
        fontSize: '1.1rem',
      },
    },
    button: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    caption: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    overline: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
});

// Apply responsive font sizes to all typography variants
export const theme = responsiveFontSizes(baseTheme);
